@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@300;700;900&display=swap");

.lander {
  height: 100vh;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.countdown-el {
  text-align: center;
}

.countdown-el span {
  font-size: 1, 5rem;
  color: white;
}

.etusivu-text {
  margin-top: -50px;
}

.koirat-p-text {
  margin: 0px;
  font-size: initial;
}

.koirat-b-text {
  margin-right: 10px;
}

.koirat-h4-text {
  color: #e94aa1;
  text-decoration: none;
  background-color: #ffdffe;
  border-radius: 40px;
}

.koirat-h6-text {
  margin-bottom: 0px;
  margin-top: 10px;
}

.koirat-h3-text {
  font-size: x-large;
  margin-bottom: 0px;
  margin-top: 10px;
}

.koirat-h3-text-link {
  font-size: x-large;
  margin-bottom: 0px;
  margin-top: 40px;
  margin-left: 5px;
}

.koirat-otsikko {
  font-size: smaller;
}

.bold-text {
  font-size: x-large;
  color: #3d2928;
  font-family: "Alice";
  margin-top: 50px;
}

.banneri-kuva {
  margin-top: -154;
}

.p-text-kasvattajasta {
  color: #63224c;
  font-size: 20px;
}

.kasvattajasta-title {
  font-family: "Handlee", cursive;
  color: #e94aa1;
}

.ninni-etusivu-text {
  color: #e94aa1;
  margin: 0px;
  font-family: "Cormorant Garamond", serif;
  letter-spacing: 8px;
}

.ninni-etusivu-alatext {
  color: #e94aa1;
  margin: 0px;
  font-family: "Cormorant", serif;
  letter-spacing: 5px;
  font-size: 20px;
}

.sweet-title {
  order: 2;
  color: #fe41b3;
  font-weight: 900;
  text-transform: uppercase;
  font-size: clamp(2rem, 80vw, 4rem);
  line-height: 0.75em;
  text-align: center;
  text-shadow: 3px 1px 1px #4af7ff, 2px 2px 1px #165bfb, 4px 2px 1px #4af7ff,
    3px 3px 1px #165bfb, 5px 3px 1px #4af7ff, 4px 4px 1px #165bfb,
    6px 4px 1px #4af7ff, 5px 5px 1px #165bfb, 7px 5px 1px #4af7ff,
    6px 6px 1px #165bfb, 8px 6px 1px #4af7ff, 7px 7px 1px #165bfb,
    9px 7px 1px #4af7ff;

  .span {
    display: block;
    position: relative;

    &:before {
      content: attr(data-text);
      position: absolute;
      text-shadow: 2px 2px 1px #e94aa1, -1px -1px 1px #c736f9,
        -2px 2px 1px #e94aa1, 1px -1px 1px #f736f9;
      z-index: 1;
    }

    &:nth-child(1) {
      padding-right: 2.25rem;
    }

    &:nth-child(2) {
      padding-left: 2.25rem;
    }
  }
}

.kasvattajasta-1 {
  max-width: 400px;
  max-height: 500px;
  margin-top: 30px;
}

.kasvattajasta-7 {
  max-width: 600px;
  max-height: 800px;
  margin-top: 30px;
}

.koirat-1-kuva {
  max-width: 600px;
  max-height: 700px;
}

.koirat-2-kuva {
  max-width: 600px;
  max-height: 700px;
}

.fa {
  color: white;
  font-size: 12rem;
  margin-bottom: 1rem;
}

.fab {
  color: white;
  font-size: 12rem;
  margin-bottom: 1rem;
}

.fa:hover {
  color: #fd0178;
}

.fab:hover {
  color: #fd0178;
}

.yht-p {
  font-size: smaller;
}

@media screen and (max-width: 960px) {
  .bold-text {
    font-size: large;
    margin-top: -100px;
  }

  .kasvattajasta-7 {
    margin-top: 0px;
  }

  .yht-p {
    font-size: x-small;
  }

  .fa {
    color: white;
    font-size: 4rem;
    margin-bottom: 1rem;
  }

  .fab {
    color: white;
    font-size: 4rem;
    margin-bottom: 1rem;
  }

  .yht-b {
    font-size: small;
  }

  .yht-icon {
    font-size: large;
    color: "#fdfdfd";
  }

  .koirat-2-kuva {
    margin-left: 0px;
  }

  .koirat-h3-text-link {
    margin-top: 5px;
  }

  .ninni-etusivu-alatext {
    color: #e94aa1;
    margin: 0px;
    font-family: "Cormorant", serif;
    letter-spacing: 7px;
    font-size: 10px;
  }

  .kasvattajasta-1 {
    margin-top: 0px;
  }

  .kuva2 {
    margin-top: -50px;
  }

  .koirat-h4-text {
    font-size: initial;
  }

  .etusivu-kuva-2 {
    padding: 2vh;
  }

  .banneri-kuva {
    margin-top: -100;
  }

  .sweet-title {
    font-size: clamp(2rem, 70vw, 2.5rem);
  }
}

@media only screen and (max-width: 600px) {
  .etusivu-text {
    margin-top: -50px;
  }

  .kuva2 {
    margin-top: -55px;
  }
}
