.navbar {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  width: 100%;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.navbar-logo-kasvattajasta {
  color: #e94aa1;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.navbar-logo-koirat {
  color: #e94aa1;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
  z-index: 1;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
  margin-right: 1rem;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 40px;
}

.nav-links-kasvattajasta {
  color: #e94aa1;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 40px;
}

.nav-links-koirat {
  color: #e94aa1;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 40px;
}

.nav-links:hover {
  background-color: #ff868f;
  border-radius: 40px;
  transition: all 0.4s ease-out;
}

.nav-links-koirat:hover {
  background-color: #efdfe0;
  border-radius: 40px;
  transition: all 0.4s ease-out;
}

.nav-links-kasvattajasta:hover {
  background-color: #e5cdce;
  border-radius: 40px;
  transition: all 0.4s ease-out;
}

.fa-kasvattajasta {
  color: #e94aa1;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.menu-icon-kasvattajasta {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: -20px;
    left: -200%;
    opacity: 1;
    transition: all 0.5s ease;
    touch-action: none;
  }

  .fa-kasvattajasta {
    color: #e94aa1;
  }

  .nav-item {
    display: flex;
    align-items: center;
    height: 80px;
    margin-top: 1rem;
    margin-right: -2rem;
  }

  .nav-menu.active {
    background: #ed7b9f;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    width: 100%;
    padding: 0;
    padding-bottom: 70vh;
    padding-top: 10vh;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 80%;
    display: table;
  }

  .nav-links-kasvattajasta {
    text-align: center;
    color: white;
    padding: 2rem;
    width: 80%;
    display: table;
  }

  .nav-links-koirat {
    text-align: center;
    color: white;
    padding: 2rem;
    width: 80%;
    display: table;
  }

  .nav-links:hover {
    background-color: #c357c7;
    border-radius: 40px;
  }

  .nav-links-kasvattajasta:hover {
    background-color: #c357c7;
    border-radius: 40px;
  }

  .nav-links-koirat:hover {
    background-color: #c357c7;
    border-radius: 40px;
  }

  .navbar-logo {
    position: relative;
    top: 0;
    left: 0;
    margin-left: 0px;
  }

  .navbar-logo-kasvattajasta {
    position: relative;
    top: 0;
    left: 0;
    margin-left: 0px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 2;
  }

  .menu-icon-kasvattajasta {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 2;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .fa-times-kasvattajasta {
    color: #e94aa1;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    display: none;
  }
}
