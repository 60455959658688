.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App {
  background-image: linear-gradient(
    to right top,
    #e083b7,
    #e582b3,
    #ea81af,
    #ef81ab,
    #f380a6,
    #f8819e,
    #fc8397,
    #ff868f,
    #ff8d84,
    #ff9579,
    #ff9e6f,
    #fda867
  );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}

.App-kasvattajasta {
  background-color: #f9e7e5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}

.App-koirat {
  background-color: #fdfdfd;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: #fdfdfd;
  text-align: center;
}

.App-link {
  color: #61dafb;
}
