.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  max-width: 1057px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 400px;
}

@media only screen and (max-width: 600px) {
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 6rem;
  }

  .swiper {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 91px;
    max-width: 380px;
  }
}

@media screen and (max-width: 960px) {
  .swiper {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 91px;
    max-width: 380px;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.swiper-3d .swiper-slide-shadow-left {
  background-image: none;
}

.swiper-3d .swiper-slide-shadow-right {
  background-image: none;
}
